<template>
    <v-hover v-slot:default="{ hover }">
        <div class="insert-toolbar">
            <div v-if="hover" :style="{top: '23px!important'}" class="insert-toolbar-stroke primary"/>
            <v-row v-if="hover" align="center" dense justify="start" no-gutters>
                <v-btn class="ms-10 mt-2" color="primary" rounded small
                       @click="insertQuestionAfter(QuestionType.check)">
                    <v-icon :left="!isRTL" :right="isRTL">mdi-check</v-icon>
                    Check
                </v-btn>
                <v-btn v-if="!isChild" class="ms-3 mt-2" color="primary" rounded small
                       @click="insertQuestionAfter(QuestionType.section)">
                    <v-icon :left="!isRTL" :right="isRTL">mdi-rectangle-outline</v-icon>
                    Section
                </v-btn>
            </v-row>
        </div>
    </v-hover>
</template>
<script>
import {QuestionType} from '@/modules/forms/enums/question-type';
import {INSERT_INSPECTION_FORM_QUESTION} from "@/modules/app/store/mutation-types";

export default {
    name: 'KurccQuestionPageActionBar',
    props: {
        isChild: {
            type: Boolean,
            required: true
        },
        indexes: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            QuestionType
        }
    },
    computed: {
        isRTL() {
            return this.$vuetify.rtl
        },
    },
    methods: {
        insertQuestionAfter(questionType) {
            const {
                pageIndex,
                grandparentIndex,
                parentIndex,
                questionIndex
            } = this.indexes
            let insertIndex = questionIndex + 1

            this.$store.commit(INSERT_INSPECTION_FORM_QUESTION, {
                pageIndex,
                parentIndex,
                grandparentIndex,
                insertIndex,
                questionType
            })
        },
    }
}
</script>
<style lang="scss" scoped>

.insert-toolbar {
    z-index: 2;
    margin: -14px auto;
    width: 90%;
    height: 28px;
    position: relative;

    .insert-toolbar-stroke {
        position: absolute;
        top: 14px;
        height: 1px;
        width: 100%;
        border-bottom: 1px solid;
    }
}
</style>
